import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { VStack, Text } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import { FiPlus, FiMinus, FiRefreshCw } from "react-icons/fi";

interface Node {
  id: string;
  host: string;
  region: string;
  status: string;
}

interface NodesProps {
  nodes: Node[];
}

export default function Nodes({ nodes: defaultNodes }: NodesProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [nodes, setNodes] = useState(defaultNodes);

  const handleAddNode = (newNode: Node) => {
    setNodes([...nodes, { ...newNode, status: "Active" }]);
  };

  const handleRemoveNode = (nodeId: string) => {
    setNodes(nodes.filter((node) => node.id !== nodeId));
  };

  const handleRenewNode = (nodeId: string) => {
    console.log(`Renewing node: ${nodeId}`);
  };

  return (
    <>
      <VStack spacing={4} align="stretch">
        <Text>
          These are the currently selected nodes for your project. You can
          delete, renew, or extend them using the actions provided.
        </Text>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Host</Th>
              <Th>Region</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {nodes.map((node) => (
              <Tr key={node.id}>
                <Td>{node.id}</Td>
                <Td>{node.host}</Td>
                <Td>{node.region}</Td>
                <Td>{node.status}</Td>
                <Td>
                  <IconButton
                    aria-label="Remove node"
                    icon={<FiMinus />}
                    size="sm"
                    colorScheme="red"
                    variant="outline"
                    mr={2}
                    onClick={() => handleRemoveNode(node.id)}
                  />
                  <IconButton
                    aria-label="Renew node"
                    icon={<FiRefreshCw />}
                    size="sm"
                    colorScheme="green"
                    variant="outline"
                    onClick={() => handleRenewNode(node.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Button leftIcon={<FiPlus />} colorScheme="blue" onClick={onOpen}>
          Add Node
        </Button>
      </VStack>
      <AddNodeModal
        isOpen={isOpen}
        onClose={onClose}
        onAddNode={handleAddNode}
        availableNodes={[
          {
            id: "0x789...",
            host: "host3.com",
            region: "as",
            status: "Active",
            currentEpoch: true,
            renew: true,
          },
          {
            id: "0xabc...",
            host: "host4.com",
            region: "sa",
            status: "Active",
            currentEpoch: true,
            renew: false,
          },
          {
            id: "0xdef...",
            host: "host5.com",
            region: "eu",
            status: "Active",
            currentEpoch: false,
            renew: true,
          },
        ]}
      />
    </>
  );
}

interface AddNodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddNode: (node: Node) => void;
  availableNodes: (Node & { currentEpoch: boolean; renew: boolean })[];
}

function AddNodeModal({
  isOpen,
  onClose,
  onAddNode,
  availableNodes: defaultAvailableNodes,
}: AddNodeModalProps) {
  const [availableNodes] = useState(defaultAvailableNodes);
  const [selectedNodes, setSelectedNodes] = useState<null | Record<
    string,
    string
  >>({});

  const handleCheckboxChange = (nodeId: string) => {
    setSelectedNodes((prev) => {
      const newSelected = { ...prev };
      if (newSelected[nodeId]) {
        delete newSelected[nodeId];
      } else {
        newSelected[nodeId] = "";
      }
      return newSelected;
    });
  };

  const handleSelectionChange = (nodeId: string, option: string) => {
    setSelectedNodes((prev) => ({
      ...prev,
      [nodeId]: option,
    }));
  };

  const handleReserveNodes = () => {
    const nodesToReserve = Object.entries(selectedNodes ?? {})
      .filter(([_, option]) => option !== "")
      .map(([nodeId, option]) => {
        const node = availableNodes.find((n) => n.id === nodeId);
        return { ...node, reservedFor: option };
      });
    nodesToReserve.forEach((node) => onAddNode(node as Node));
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay />
      <ModalContent borderRadius="md">
        <ModalHeader borderBottomWidth="1px" py={4}>
          Available Content Nodes
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <Box overflowX="auto">
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>ID</Th>
                  <Th>Host</Th>
                  <Th>Region</Th>
                  <Th>Reserve For</Th>
                </Tr>
              </Thead>
              <Tbody>
                {availableNodes.map((node) => (
                  <Tr key={node.id}>
                    <Td>
                      <Checkbox
                        isChecked={
                          selectedNodes !== null && node.id in selectedNodes
                        }
                        onChange={() => handleCheckboxChange(node.id)}
                      />
                    </Td>
                    <Td>{node.id}</Td>
                    <Td>{node.host}</Td>
                    <Td>{node.region}</Td>
                    <Td>
                      <Select
                        size="sm"
                        value={selectedNodes?.[node.id] || ""}
                        onChange={(e) =>
                          handleSelectionChange(node.id, e.target.value)
                        }
                        isDisabled={
                          selectedNodes ? !(node.id in selectedNodes) : true
                        }
                      >
                        <option value="">Select</option>
                        {node.currentEpoch && (
                          <option value="current">Current Epoch</option>
                        )}
                        {node.renew && <option value="renew">Renew</option>}
                      </Select>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </ModalBody>
        <ModalFooter borderTopWidth="1px" py={3}>
          <Button variant="ghost" onClick={onClose} mr={3}>
            Close
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleReserveNodes}
            isDisabled={Object.values(selectedNodes ?? {}).every(
              (v) => v === "",
            )}
          >
            Reserve Selected
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
